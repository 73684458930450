<template>
    <div>
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="高级定制管理列表" name="first">
          <list v-if="activeName == 'first'" />
        </el-tab-pane>
        <el-tab-pane label="草稿箱" name="second"> 
          <list v-if="activeName == 'second'" :drafts='true' />
        </el-tab-pane>
      </el-tabs>
    </div>
  </template>
  
  <script>
  import list from './components/list';
  export default {
    name: "spike",
    components:{
      list
    },
    data() {
      return {
        activeName: "first",
      }
    },
    methods: {
      handleClick(tab) {
        const { name } = tab;
        this.activeName = name;
      },
    },
  };
  </script>
  
  <style lang="scss" scoped>
  </style>