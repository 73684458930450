import httpUtil from "@/utils/httpUtil";

/**获取3d高定列表 */
export const selectGoodsList =  params => httpUtil.post("/api/crmPc/hauteCouture/selectGoodsList", params);

/**保存3d高定列表 */
export const addGoodsInfo =  params => httpUtil.post("/api/crmPc/hauteCouture/addGoodsInfo", params);

/**修改3d高定列表 */
export const updateGoodsInfo =  params => httpUtil.post("/api/crmPc/hauteCouture/updateGoodsInfo", params);

/**删除3d高定列表 */
export const delGoodsInfo =  params => httpUtil.post("/api/crmPc/hauteCouture/delGoodsInfo", params);

/**发布3d高定列表 */
export const releaseGoods =  params => httpUtil.post("/api/crmPc/hauteCouture/releaseGoods", params);
